import { Button, Image, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import Logo from '../assets/images/playbook_white.svg';
import AimeSports from '../assets/images/aime_sports.svg'
import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
function LandingPage(props) {
    const history = useHistory();
    const Start = () => {
        history.push('/about-playbook')
    }
    return (
        <Stack bg="white" align="center">
            <Stack bg="#000000"position={'relative'} minH="100vh" w={isMobile ? "100%" : "600px"} align="center" pt="60px" px="25px" pb="35px" spacing="41px">
                <Image src={Logo} alt="App Logo" />
                <Text pb="70px" fontWeight="700" fontSize="24px" textAlign="center" color="white">A resource to start you on your Diversity and Inclusion journey
                </Text>
                <Stack position={'absolute'} bottom={'35px'} px="24px" align="center" w="100%">
                    <Button
                        fontWeight="700"
                        fontSize="16px"
                        w="100%"
                        bg="#189DD9"
                        color="white"
                        border="1px solid black"
                        minH="56px"
                        borderRadius="29px"
                        onClick={Start}
                    >
                        Start 
                    </Button>
                    <Text pt="20px" color="#ffffff" fontSize="14px" fontWeight="700" >Powered by</Text>
                    <Image src={AimeSports} w="48px" h="48px" alt="Developer Company Logo" />
                </Stack>
            </Stack>
        </Stack>
    );
}

export default LandingPage;