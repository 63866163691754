import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './assets/styles.css';
import AboutPlaybook from './Components/BurgerMenu/AboutPlaybook';
import AboutUs from './Components/BurgerMenu/AboutUs';
import PartnersMenu from './Components/BurgerMenu/PartnersMenu';
import PrivacyAndData from './Components/BurgerMenu/PrivacyAndData';
import Resources from './Components/BurgerMenu/Resources';
import Statistics from './Components/BurgerMenu/Statistics';
import FinishQuestions from './Components/FinishQuestions';
import GetStarted from './Components/GetStarted';
import Header from './Components/Header';
import LandingPage from './Components/LandingPage';
import PartnerContactRegister from './Components/PartnerContactRegister';
import Partners from './Components/Partners';
import ThankYouNote from './Components/ThankYouNote';




// fuction to add navbar
function withLayout(WrappedComponent) {
  return class extends React.Component {
    render() {
      return (
        <>
         <Header/>
          <WrappedComponent />

        </>
      );
    }
  }
}


function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route path="/start" component={withLayout(GetStarted)} />
        <Route path="/finish" component={FinishQuestions} />
        <Route path="/thank-you" component={withLayout(ThankYouNote)} />
        <Route path="/partner" component={withLayout(Partners)} />
        <Route path="/register" component={withLayout(PartnerContactRegister)} />

        {/* burger menu routes */}

        <Route path="/about" component={withLayout(AboutUs)} />
        <Route path="/about-playbook" component={withLayout(AboutPlaybook)} />
        <Route path="/partners" component={withLayout(PartnersMenu)} />
        <Route path="/statistics" component={withLayout(Statistics)} />
        <Route path="/resources" component={withLayout(Resources)} />
        <Route path="/privacy" component={withLayout(PrivacyAndData)} />
      </Switch>

    </BrowserRouter>
  );
}

export default App;
