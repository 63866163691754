import { HamburgerIcon } from '@chakra-ui/icons';
import { CloseButton, HStack, Image, Stack, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';
import Home from '../assets/images/homeicon.svg';
import HamburgerMenu from '../assets/images/hamburger_menu.svg';
import CloseIcon from '../assets/images/close_icon.svg';
import Logo from '../assets/images/NewLogo.svg';
import '../assets/menu.css';

function Header(props) {
    const [rightMenuVisible, setRightMenuVisible] = useState(false);

    const rightMenuAnimation = useSpring({
        opacity: rightMenuVisible ? 1 : 0,
        transform: rightMenuVisible ? `translateX(0)` : `translateX(100%)`
    });
    const history = useHistory();
    const Statistics = () => {
        history.push('/statistics');
    }
    const Privacy = () => {
        history.push('/privacy');
    }
    const Partners = () => {
        history.push('/partners');
    }
    const AboutUs = () => {
        history.push('/about');
    }
    const AboutPlaybook = () => {
        history.push('/about-playbook');
    }
    const Contact = () => {
        history.push('/start');
    }
    const Resources = () => {
        history.push('/resources');
    }


    return (
        <>
            <Stack bg="black" alignItems="center">
                <Stack bg="white" w={isMobile ? "100%" : "768px"} p="20px" spacing={0} position="relative" >
                    <HStack justify="space-between" align="flex-start" spacing={0}>
                        <Image src={Home} w="19px" h="19px" onClick={Contact} alt="Home Icon" />
                        <Image src={Logo} alt="App Logo" />
                        {!rightMenuVisible ?
                            <Image src={HamburgerMenu} zIndex="31" 
                                onClick={() => setRightMenuVisible(true)} />
                            :
                            <Image src={CloseIcon} zIndex={2} onClick={() => setRightMenuVisible(false)} borderRadius="100%" color="white" />
                        }
                    </HStack>
                    {rightMenuVisible &&

                        <animated.div className="menu menu--right" style={rightMenuAnimation}>

                            <Stack spacing="39px" m="0" pl="35px" py="52px">
                                <Text onClick={AboutUs} fontWeight="600" color="white" fontSize="18px">About us</Text>
                                <Text onClick={AboutPlaybook} fontWeight="600" color="white" fontSize="18px">About the Playbook</Text>
                                <Text onClick={Statistics} fontWeight="600" color="white" fontSize="18px">Statistics</Text>
                                <Text onClick={Resources} fontWeight="600" color="white" fontSize="18px">Resources</Text>
                                <Text onClick={Privacy} fontWeight="600" color="white" fontSize="18px">Your Privacy &amp; Data</Text>
                            </Stack>
                        </animated.div>
                    }

                </Stack>
            </Stack>


        </>
    );
}

export default Header;