
import { Link, List, ListItem, OrderedList, Stack, Text, UnorderedList } from '@chakra-ui/layout';
import React from 'react';
import { isMobile } from 'react-device-detect';

function PrivacyAndData(props) {
    return (
        <Stack bg="black" align="center" minH="100vh">
            <Stack bg="white" w={isMobile ? "100%" : "768px"} align="center" px="20px" spacing="14px" pb="60px" pt="5px">
                <Stack align="center"spacing="3px">
                    <Text fontWeight="700" fontSize="24px" color="#009FE3">Privacy Policy</Text>
                    <Text fontSize="8px">Latest Update: 20/10/2021</Text>
                </Stack>
                <Stack w="100%" spacing="0" >
                    <Stack spacing="3px" fontWeight="400" fontSize="9px">
                        <Text fontWeight="700" fontSize="11px">  Our contact details</Text>
                        <List  fontSize="9px">
                            <ListItem>Name: PLAYBOOK FOR CHANGE</ListItem>
                            <ListItem> Address: 29 Veals Mead, Mitcham, England, CR4 3SB</ListItem>
                            <ListItem>E-mail:<Link  fontWeight="700" color="#009FE3"href="mailto:playbookforchange@gmail.com" > playbookforchange@gmail.com  </Link></ListItem>
                        </List>

                        <Text fontWeight="700" fontSize="11px">The type of personal information we collect</Text>
                        <Text fontWeight="400" fontSize="9px">We currently collect and process the following information:</Text>
                        <UnorderedList  pl="15px"  fontSize="9px">
                            <ListItem>Personal identifiers, contacts and characteristics – Email only </ListItem>
                            <ListItem>Management level linked to your place of work </ListItem>
                            <ListItem>Gender and Ethic group (Optional) </ListItem>
                            <ListItem>Your industry of work (Optional) </ListItem>
                        </UnorderedList>
                        <Text fontWeight="700" fontSize="11px">How we get the personal information and why we have it</Text>
                        <Text fontWeight="400" fontSize="9px"> Most of the personal information we process is provided to us directly by you for one of the following reasons:</Text>
                        <UnorderedList pl="15px"  fontSize="9px">
                            <ListItem>To ensure that we provide the most relevant information to you once in the ‘results’ section of the PLAYBOOK</ListItem>
                            <ListItem>To better understand the landscape of the industries people, work in and form what gender and ethnic backgrounds people come from (Optional questions)</ListItem>
                        </UnorderedList>
                        <Text fontWeight="400" fontSize="9px">
                            We use the information that you have given us in order to accurately provide the most relevant results information and give us greater insight into the Diversity and Inclusion landscape <br />
                            We may share this information with The Sports Industry Group to develop further bodies of work but your personal identifiers will not be shared with anyone.<br />

                            Under the General Data Protection Regulation (GDPR), the lawful bases we rely on for processing this information are:<br />
                        </Text>
                        <List fontWeight="700" fontSize="9px">
                            <ListItem> (a) Your consent. You are able to remove your consent at any time. You can do this by contacting<Link color="#009FE3"href="mailto:playbookforchange@gmail.com" > playbookforchange@gmail.com</Link> </ListItem>
                            <ListItem>  (b) We have a legitimate interest.</ListItem>
                        </List>

                        <Text fontWeight="700" fontSize="11px"> How we store your personal information</Text>
                        <Text fontWeight="400" fontSize="9px">
                            Your information is securely stored at 110 High Holborn,
                            London, WC1V 6JS.

                            We keep your, Personal Identifiers, Management level information, Ethnicity Group and Gender for 12 months. We will then securely dispose of your information.
                        </Text>
                        <Text fontWeight="700" fontSize="11px">Your data protection rights</Text>
                        <Text fontWeight="400" fontSize="9px"> Under data protection law, you have rights including:</Text>
                        <Text fontWeight="700" fontSize="9px">
                            Your right of access
                            <Text as="span" fontWeight="400" fontSize="9px"> - You have the right to ask us for copies of your personal information.</Text>
                        </Text>

                        <Text fontWeight="700" fontSize="9px">
                            Your right to rectification
                            <Text as="span" fontWeight="400" fontSize="9px">  - You have the right to ask us to rectify personal information you think is inaccurate. You also have the right to ask us to complete information you think is incomplete.</Text>
                        </Text>
                        <Text fontWeight="700" fontSize="9px">
                            Your right to erasure
                            <Text as="span" fontWeight="400" fontSize="9px">- You have the right to ask us to erase your personal information in certain circumstances.
                                Your right to restriction of processing - You have the right to ask us to restrict the processing of your personal information in certain circumstances.</Text>
                        </Text>
                        <Text fontWeight="700" fontSize="9px">
                            Your right to object to processing
                            <Text as="span" fontWeight="400" fontSize="9px"> - You have the the right to object to the processing of your personal information in certain circumstances.</Text>
                        </Text>
                        <Text fontWeight="700" fontSize="9px">
                            Your right to data portability
                            <Text as="span" fontWeight="400" fontSize="9px">- You have the right to ask that we transfer the personal information you gave us to another organisation, or to you, in certain circumstances.</Text>
                        </Text>
                        <Text as="span" fontWeight="400" fontSize="9px">

                            You are not required to pay any charge for exercising your rights. If you make a request, we have one month to respond to you.<br />
                            Please contact us at <Link  fontWeight="700" color="#009FE3" href="mailto:playbookforchange@gmail.com" >playbookforchange@gmail.com</Link> if you wish to make a request.
                        </Text>
                        <Text fontWeight="700" fontSize="11px"> How to complain</Text>
                        <Text fontWeight="400" fontSize="9px">  If you have any concerns about our use of your personal information, you can make a complaint to us at
                            <Link color="#009FE3"fontWeight="700" href="mailto:playbookforchange@gmail.com" > playbookforchange@gmail.com</Link> </Text>
                        <Text fontWeight="400" fontSize="9px">You can also complain to the ICO if you are unhappy with how we have used your data.<br />
                            The ICO’s address:<br />
                            Information Commissioner’s Office<br />
                            Wycliffe House<br />
                            Water Lane<br />
                            Wilmslow<br />
                            Cheshire<br />
                            SK9 5AF<br />
                            Helpline number: 0303 123 1113<br />
                            ICO website:<Link  fontWeight="700" href="https://www.ico.org.uk "  >https://www.ico.org.uk </Link> <br />
                        </Text>




                    </Stack>
                </Stack>

            </Stack>



        </Stack>
    );
}

export default PrivacyAndData;

