import { Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { isMobile } from 'react-device-detect';

function PartnersMenu(props) {
    return (
        <Stack bg="black" align="center" >
        <Stack bg="white" w={isMobile ? "100%" : "768px"}h="100vh" align="center" px="20px" spacing="17px" pb="30px" pt="5px">
        <Text fontWeight="700" fontSize="24px" color="#009FE3">Partners</Text>
        <Text fontWeight="700" color="#949494" fontSize="14px" textAlign="center">Coming Soon</Text>
        </Stack>
        </Stack>
    );
}

export default PartnersMenu;