import { Button, HStack, Image, Link, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import Email from '../assets/images/email.svg';
import Download from '../assets/images/download.svg';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import { useToast } from "@chakra-ui/react"
import Results from './Results';
import Header from './Header';
import BlueHome from '../assets/images/blue_homeicon.svg'
import WhiteHome from '../assets/images/whitehomeicon.svg'
import { isMobile } from 'react-device-detect';
import axios from 'axios';
import Clipboard from 'react-copy-to-clipboard'
var qs = require('qs');

function FinishQuestions(props) {
    const history = useHistory();
    const [doc, setDoc] = useState(null);
    const [email, setEmail] = useState(null);
    const [isResults, setIsResults] = useState(false);
    const [title, setTitle] = useState(null);
    const [fileName, setFileName] = useState("");
    const [clicked, setClicked] = useState(false);
    const toast = useToast();

    const handleButtonClicked = () => {
        setClicked(!clicked)
        history.push('/start')
    }


    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        if (params.file == null) {
            toast({
                title: "Warning",
                description: "Cannot access this page directly",
                status: "warning",
                duration: 3000,
                isClosable: true,
            })
            history.push('/start')
        }

        setDoc(params.file)
        console.log("🚀 ~ useEffect ~ params.file", params.file.replace('/static/media/', ''))
        setFileName(params.file.replace('/static/media/', ''))
        setTitle(params.title)
        setEmail(params.email);
    }, [])

    const Resources = () => {
        history.push('/resources')
    }



    function onClose() {
        setIsResults(false);
    }

    function sendFileRequest() {


        var data = qs.stringify({
            'email': email,
            'fileName': extractFileName(fileName)
        });
        var config = {
            method: 'post',
            url: 'https://playbook-for-change.glitch.me/sendEmail',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        };

        axios(config).then(res => {
            console.log("🚀 ~ sendFileRequest ~ res", res)
            toast({
                status: "success",
                description: "File has been sent to your email",
                position: "bottom"
            })
            history.push('/thank-you')
        }).catch(err => {
            console.log("🚀 ~ sendFileRequest ~ err", err)
        });

    }

    function extractFileName(file) {
        let f0 = file.split("Management.")[0];
        f0 += "Management.pdf";
        return f0;
    }


    const handleOnSubmit = async () => {
        console.log('sharing');
        if (navigator.share) {
            await navigator.share({
                title: "D & I survey App",
                text: "visit D & I survey App",
                url: "https://d-i-survey-v2.netlify.app/",
            })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error in sharing', error));
        } else {
            console.log(`system does not support sharing files.`);
        }
    }
    const showToast = () => {
        toast({
            title: "Suceess",
            description: "Link copied to clipboard",
            status: "success",
            duration: 3000,
            position: 'top-right',
            isClosable: true,
        })
    }

    return (
        <Stack bg="black" align="center">
            {isResults ?
                <Results title={title} document={doc} onClose={onClose} />
                :
                <Stack bg="white" w={isMobile ? "100%" : "768px"} minH="100vh" position={"relative"} >
                    <Header />
                    <Stack bg="white" w={isMobile ? "100%" : "768px"} align="center" px="20px" spacing="30px" pb="30px">
                        <Stack w="100%" align="center">
                            <Text fontWeight="700" fontSize="24px" color="#009FE3">Thanks, you’re all done.</Text>
                        </Stack>
                        <Link href={doc} download w="100%">
                            <Button
                                fontWeight="700"
                                fontSize="16px"
                                w="100%"
                                bg="#189DD9"
                                color="white"
                                _hover={{ color: "white", bg: "#189DD9" }}
                                _focus={{ color: "white", bg: "#189DD9" }}
                                minH="56px"
                                onClick={() => (history.push('/register'))}
                                border="1px solid #189DD9"
                                borderRadius="12px">
                                Download your Playbook pdf
                            </Button>
                        </Link>
                        <Stack w="100%" align="center" onClick={() => sendFileRequest()}>
                            <Button
                                fontWeight="700"
                                fontSize="16px"
                                w="100%"
                                bg="#189DD9"
                                color="white"
                                _hover={{ color: "white", bg: "#189DD9" }}
                                _focus={{ color: "white", bg: "#189DD9" }}
                                minH="56px"
                                border="1px solid #189DD9"
                                borderRadius="12px" type="submit">
                                Email your Playbook pdf
                            </Button>
                        </Stack>
                        <Stack pt={'130px'} w={'100%'} spacing={'43px'} align={'center'} >

                            <Text onClick={Resources} borderBottom={'2px solid #189DD9'} fontSize={'24px'} fontWeight={'700'} color={'#189DD9'} cursor={'pointer'}>See more resources</Text>
                            <Clipboard text='https://d-i-survey-v2.netlify.app/' onCopy={() => showToast()} >
                                <Text borderBottom={'2px solid #189DD9'} fontSize={'24px'} fontWeight={'700'} color={'#189DD9'} w={'fit-content'} cursor={'pointer'} >Share the survey</Text>
                            </Clipboard>

                        </Stack>

                    </Stack>
                    <Button
                        position="absolute"
                        bottom={"70px"}
                        fontWeight="700"
                        fontSize="16px"
                        w="90%"
                        color={clicked ? "#ffffff" : "#189DD9"}
                        bg={clicked ? "#189DD9" : "white"}
                        minH="56px"
                        borderRadius={'42px'}
                        border="1px solid #189DD9"
                        align="center"
                        alignSelf={"center"}
                        justify="center"
                        onClick={() => handleButtonClicked()}
                    >
                        <HStack w="100%" h="100%" align={"center"} justify={"center"}>
                            <Image src={clicked ? WhiteHome : BlueHome} />
                            <Text> Take Survey Again</Text>
                        </HStack>

                    </Button>
                </Stack>
            }
        </Stack>
    );
}

export default FinishQuestions;