import { Button, HStack, Link, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { isMobile } from 'react-device-detect';

function Resources(props) {
    return (
        <Stack bg="black" align="center" >
            <Stack bg="white" w={isMobile ? "100%" : "768px"} h="100vh" align="center" px="20px" spacing="50px" pb="30px" pt="5px">
                <Stack align={"center"}>
                    <Text fontWeight="700" fontSize="24px" color="#189DD9">Resources</Text>
                    <Text textAlign={"center"} color={'#1D1D1B'} fontSize={'12px'}>
                        (Some links below will redirect you to third-party sites)
                    </Text>
                </Stack>

                <HStack w={'100%'} justify={'space-between'}>
                    <Text fontWeight="700" fontSize="14px" color="#1D1D1B">D&I Resources For Everyone</Text>

                    <Link href="https://firebasestorage.googleapis.com/v0/b/aime-recruitment-auth.appspot.com/o/mvp-customer-app-images%2F200624_Diversity%20%26%20Inclusion%20NextGen%20(3).pdf?alt=media&token=7f624a19-6cd6-4278-bf03-00e4031fd778" isExternal >
                        <Button
                            h="56px"
                            w="125px"
                            borderRadius="42px"
                            bg="#189DD9"
                            color="white"
                            fontSize="16px"
                            fontWeight="700"
                            _focus={{ cursor: "pointer", border: "1px solid #189DD9", color: "#189DD9", bg: "white" }}
                            _hover={{ cursor: "pointer", border: "1px solid #189DD9", color: "#189DD9", bg: "white" }}
                        >
                            View
                        </Button>
                    </Link>
                </HStack>
                <HStack w={'100%'} justify={'space-between'}>
                    <Stack>
                        <Text fontWeight="700" fontSize="14px" color="#1D1D1B">Implicit Bias Training Links</Text>
                        <Text fontSize="14px" color="#1D1D1B">(Please refer to the Race, Skintone,<br /> Asian & Arab & Muslim IAT)</Text>

                    </Stack>
                    <Link href="https://implicit.harvard.edu/implicit/" isExternal >
                        <Button
                            h="56px"
                            w="125px"
                            borderRadius="42px"
                            bg="#189DD9"
                            color="white"
                            fontSize="16px"
                            fontWeight="700"
                            _focus={{ cursor: "pointer", border: "1px solid #189DD9", color: "#189DD9", bg: "white" }}
                            _hover={{ cursor: "pointer", border: "1px solid #189DD9", color: "#189DD9", bg: "white" }}
                        >
                            View
                        </Button>
                    </Link>
                </HStack>
                <HStack w={'100%'} justify={'space-between'}>
                    <Text fontWeight="700" fontSize="14px" color="#1D1D1B">Social Identity Wheel</Text>
                    <Link href='https://fairygodboss.com/career-topics/identity-wheel' isExternal>
                        <Button
                            h="56px"
                            w="125px"
                            borderRadius="42px"
                            bg="#189DD9"
                            color="white"
                            fontSize="16px"
                            fontWeight="700"
                            _focus={{ cursor: "pointer", border: "1px solid #189DD9", color: "#189DD9", bg: "white" }}
                            _hover={{ cursor: "pointer", border: "1px solid #189DD9", color: "#189DD9", bg: "white" }}>
                            View
                        </Button>
                    </Link>
                </HStack>
                <HStack w={'100%'} justify={'space-between'}>
                    <Text fontWeight="700" fontSize="14px" color="#1D1D1B">
                        Social Identity Wheel
                        Exercise Template

                    </Text>
                    <Link href='https://www.nonprofnetwork.org/resources/Documents/Social%20Identity%20Wheels%20Final.pdf' isExternal>
                        <Button
                            h="56px"
                            w="125px"
                            borderRadius="42px"
                            bg="#189DD9"
                            color="white"
                            fontSize="16px"
                            fontWeight="700"
                            _focus={{ cursor: "pointer", border: "1px solid #189DD9", color: "#189DD9", bg: "white" }}
                            _hover={{ cursor: "pointer", border: "1px solid #189DD9", color: "#189DD9", bg: "white" }}>
                            View
                        </Button>
                    </Link>
                </HStack>
                <HStack w={'100%'} justify={'space-between'}>
                    <Text fontWeight="700" fontSize="14px" color="#1D1D1B">Privilege Article</Text>
                    <Link href='https://www.bbc.co.uk/newsround/53013963' isExternal>
                        <Button
                            h="56px"
                            w="125px"
                            borderRadius="42px"
                            bg="#189DD9"
                            color="white"
                            fontSize="16px"
                            fontWeight="700"
                            _focus={{ cursor: "pointer", border: "1px solid #189DD9", color: "#189DD9", bg: "white" }}
                            _hover={{ cursor: "pointer", border: "1px solid #189DD9", color: "#189DD9", bg: "white" }}>
                            View
                        </Button>
                    </Link>
                </HStack>
            </Stack>
        </Stack>
    );
}

export default Resources;