import { Stack } from '@chakra-ui/layout';
import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import '../assets/Sample.css'
import { CloseButton, HStack, Text } from '@chakra-ui/react';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
};
function Results(props) {
    const { document, title, onClose } = props;
    const [numPages, setNumPages] = useState(null);

    function onDocumentLoadSuccess({ numPages: nextNumPages }) {
        setNumPages(nextNumPages);
    }

    return (
        <Stack>
            <Stack >
                <HStack p="25px" justify="space-between" position="fixed" top="0" left="0" zIndex="100" bg="white" right="0" boxShadow="base">
                    <Text noOfLines="1" fontSize="24px" fontWeight="700">{title.level} Budget {title.rank} Management</Text>
                    <CloseButton onClick={onClose} borderRadius="100%" border="2px solid black" />
                </HStack>
                <div className="Example__container__document">
                    <Document
                        file={document}
                        onLoadSuccess={onDocumentLoadSuccess}
                        options={options}>
                        {
                            Array.from(
                                new Array(numPages),
                                (el, index) => (
                                    <Page
                                        key={`page_${index + 1}`}
                                        pageNumber={index + 1}
                                    />
                                ),
                            )
                        }
                    </Document>
                </div>
            </Stack>
        </Stack>
    );
}

export default Results;