import { Button, Input, Stack, Text, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import Checkbox from "react-custom-checkbox";
import { isMobile } from 'react-device-detect';
import * as Icon from "react-icons/fi";


const InitialValues = {
    name: null,
    company: null,
    role: null,
    email: null
}

function PartnerContactRegister(props) {
    const [values, setValues] = useState(InitialValues);
    const toast = useToast();
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
    }
    const Finish = () => {
        toast({
            title: "Success",
            description: "Data saved",
            status: "success",
            duration: 3000,
            isClosable: true,
        })
    }
    return (
        <Stack bg="black" align="center">
            <Stack align="center" px="15px" spacing="17px" pb="30px" bg="white" w={isMobile ? "100%" : "768px"} minH="100vh">
                <Text fontWeight="700" fontSize="24px" color="#009FE3" textAlign="center">Contact The Playbook</Text>


                <form onSubmit={e => {
                    e.preventDefault();
                    Finish();
                }}>
                    <Stack w="100%" spacing="17px" pb="35px">
                        <Input required onChange={handleInputChange} value={values.name} name="name" placeholder=" Your Name" _placeholder={{ color: "#1D1D1B" }} fontSize="15px" fontWeight="600" borderRadius="none" borderBottom="1px solid #C4C4C4" border='none' borderColor="#1D1D1B" />
                        <Input required onChange={handleInputChange} value={values.company} name="company" placeholder="Company" _placeholder={{ color: "#1D1D1B" }} fontSize="15px" fontWeight="600" borderRadius="none" borderBottom="1px solid #C4C4C4" border='none' borderColor="#1D1D1B" />
                        <Input required onChange={handleInputChange} value={values.role} name="role" placeholder="Your Role" _placeholder={{ color: "#1D1D1B" }} fontSize="15px" fontWeight="600" borderRadius="none" borderBottom="1px solid #C4C4C4" border='none' borderColor="#1D1D1B" />
                        <Input required onChange={handleInputChange} value={values.email} name="email" placeholder="Email Address" _placeholder={{ color: "#1D1D1B" }} fontSize="15px" fontWeight="600" borderRadius="none" borderBottom="1px solid #C4C4C4" border='none' borderColor="#1D1D1B" />
                        <Stack pt={'15px'}pb={'8px'}>
                            <Text textAlign={'center'} fontSize="14px" fontWeight="700" color="#1D1D1B">I’m interested in learning more about</Text>
                            <Text textAlign={'center'} fontSize="12px" fontWeight="600" color="#1D1D1B">(Tick as many as appropriate)</Text>
                        </Stack>
                        <Checkbox
                            icon={<Icon.FiCheck color="#1D1D1B" size={18} />}
                            name="my-input"
                            borderColor="#1D1D1B"
                            borderRadius={5}
                            size={22}
                            style={{ cursor: "pointer" }}
                            labelStyle={{ marginLeft: 6, userSelect: "none", fontSize: "14px", color: "#1D1D1B", fontWeight: "600" }}
                            label="Recruitment & Hiring"
                        />
                        <Checkbox
                            icon={<Icon.FiCheck color="#1D1D1B" size={18} />}
                            name="my-input"
                            borderColor="#1D1D1B"
                            borderRadius={5}
                            size={22}
                            style={{ cursor: "pointer" }}
                            labelStyle={{ marginLeft: 6, userSelect: "none", fontSize: "14px", color: "#1D1D1B", fontWeight: "600" }}
                            label="Benefits & Working Conditions"
                        />
                        <Checkbox
                            icon={<Icon.FiCheck color="#1D1D1B" size={18} />}
                            name="my-input"
                            borderColor="#1D1D1B"
                            borderRadius={5}
                            size={22}
                            style={{ cursor: "pointer" }}
                            labelStyle={{ marginLeft: 6, userSelect: "none", fontSize: "14px", color: "#1D1D1B", fontWeight: "600" }}
                            label="Assessment & Promotion"
                        />
                        <Checkbox
                            icon={<Icon.FiCheck color="#1D1D1B" size={18} />}
                            name="my-input"
                            borderColor="#1D1D1B"
                            borderRadius={5}
                            size={22}
                            style={{ cursor: "pointer" }}
                            labelStyle={{ marginLeft: 6, userSelect: "none", fontSize: "14px", color: "#1D1D1B", fontWeight: "600" }}
                            label="Meetings & Social Connections"
                        />
                        <Checkbox
                            icon={<Icon.FiCheck color="#1D1D1B" size={18} />}
                            name="my-input"
                            borderColor="#1D1D1B"
                            borderRadius={5}
                            size={22}
                            style={{ cursor: "pointer" }}
                            labelStyle={{ marginLeft: 6, userSelect: "none", fontSize: "14px", color: "#1D1D1B", fontWeight: "600" }}
                            label="Learning & Growth"
                        />

                    </Stack>
                    <Button
                        fontWeight="700"
                        fontSize="16px"
                        w="100%"
                        color="#189DD9"
                        bg="white"
                        _hover={{ color: "white", bg: "#189DD9" }}
                        _focus={{ color: "white", bg: "#189DD9" }}
                        minH="56px"
                        border="1px solid #189DD9"
                        borderRadius="29px"
                        type="submit"
                    >
                        Register Your Interest
                    </Button>


                </form>
            </Stack>
        </Stack>
    );
}

export default PartnerContactRegister;