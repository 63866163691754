import { Button, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';

function ThankYouNote(props) {
    const history = useHistory();
    const Next = () => {
        history.push('/register')
    }
    return (
        <Stack bg="black" align="center" >
            <Stack w={isMobile ? "100%" : "768px"} px="18px" boxSizing='border-box' spacing="30px" pb="125px" bg="white" align="center"justify={'center'} h={'calc(100vh - 95px)'} position={'relative'}>
                <Text textAlign={'center'} color={'#189DD9'} fontSize={'24px'} fontWeight={'700'} >
                    Thank you.<br />
                    Your playbook has been emailed to you.</Text>

                <Button
                    position={'absolute'}
                    bottom={"90px"}
                    fontWeight="700"
                    fontSize="16px"
                    w="90%"
                    minH="56px"
                    color={"#189DD9"}
                    bg={"white"}
                    borderRadius={'42px'}
                    border="1px solid #189DD9"
                    onClick={Next}
                >Next</Button>
            </Stack>
        </Stack >
    );
}

export default ThankYouNote;