import { Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';

function Partners(props) {
    const history = useHistory();
    const Register = () => {
        history.push('/register')
    }
    return (
        <Stack bg="black" align="center">
        <Stack px="20px" spacing="20px" pb="25px"bg="white" w={isMobile ? "100%" : "768px"} >
            <Text fontWeight="700" fontSize="24px" color="#009FE3" textAlign="center" onClick={Register} >Partners</Text>

            <a id="recruitment"></a>
            <Stack>
                <Text fontWeight="700" fontSize="18px" color="#191918" >Recruitment & Hiring</Text>
                <Stack>
                    <Text fontWeight="700" fontSize="18px" color="#949494" >Partner Name</Text>
                    <Text fontWeight="400" fontSize="14px" color="#949494" >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Magna sit lectus fermentum nulla tortor tempus curabitur.</Text>
                </Stack>
                <Stack>
                    <Text fontWeight="700" fontSize="18px" color="#949494" >Partner Name</Text>
                    <Text fontWeight="400" fontSize="14px" color="#949494" >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Magna sit lectus fermentum nulla tortor tempus curabitur.</Text>
                </Stack>
                <Stack>
                    <Text fontWeight="700" fontSize="18px" color="#949494" >Partner Name</Text>
                    <Text fontWeight="400" fontSize="14px" color="#949494" >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Magna sit lectus fermentum nulla tortor tempus curabitur.</Text>
                </Stack>
            </Stack>

            <a id="benefits"></a>
            <Stack>
                <Text fontWeight="700" fontSize="18px" color="#191918" >Benefits & Working Conditions</Text>
                <Stack>
                    <Text fontWeight="700" fontSize="18px" color="#949494" >Partner Name</Text>
                    <Text fontWeight="400" fontSize="14px" color="#949494" >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Magna sit lectus fermentum nulla tortor tempus curabitur.</Text>
                </Stack>
                <Stack>
                    <Text fontWeight="700" fontSize="18px" color="#949494" >Partner Name</Text>
                    <Text fontWeight="400" fontSize="14px" color="#949494" >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Magna sit lectus fermentum nulla tortor tempus curabitur.</Text>
                </Stack>
                <Stack>
                    <Text fontWeight="700" fontSize="18px" color="#949494" >Partner Name</Text>
                    <Text fontWeight="400" fontSize="14px" color="#949494" >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Magna sit lectus fermentum nulla tortor tempus curabitur.</Text>
                </Stack>
            </Stack>


            <a id="assessment"></a>
            <Stack>
                <Text fontWeight="700" fontSize="18px" color="#191918" >Assessment & Promotion</Text>
                <Stack>
                    <Text fontWeight="700" fontSize="18px" color="#949494" >Partner Name</Text>
                    <Text fontWeight="400" fontSize="14px" color="#949494" >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Magna sit lectus fermentum nulla tortor tempus curabitur.</Text>
                </Stack>
                <Stack>
                    <Text fontWeight="700" fontSize="18px" color="#949494" >Partner Name</Text>
                    <Text fontWeight="400" fontSize="14px" color="#949494" >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Magna sit lectus fermentum nulla tortor tempus curabitur.</Text>
                </Stack>
                <Stack>
                    <Text fontWeight="700" fontSize="18px" color="#949494" >Partner Name</Text>
                    <Text fontWeight="400" fontSize="14px" color="#949494" >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Magna sit lectus fermentum nulla tortor tempus curabitur.</Text>
                </Stack>
            </Stack>


            <a id="meetings"></a>
            <Stack>
                <Text fontWeight="700" fontSize="18px" color="#191918" >Meetings & Social Connections</Text>
                <Stack>
                    <Text fontWeight="700" fontSize="18px" color="#949494" >Partner Name</Text>
                    <Text fontWeight="400" fontSize="14px" color="#949494" >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Magna sit lectus fermentum nulla tortor tempus curabitur.</Text>
                </Stack>
                <Stack>
                    <Text fontWeight="700" fontSize="18px" color="#949494" >Partner Name</Text>
                    <Text fontWeight="400" fontSize="14px" color="#949494" >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Magna sit lectus fermentum nulla tortor tempus curabitur.</Text>
                </Stack>
                <Stack>
                    <Text fontWeight="700" fontSize="18px" color="#949494" >Partner Name</Text>
                    <Text fontWeight="400" fontSize="14px" color="#949494" >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Magna sit lectus fermentum nulla tortor tempus curabitur.</Text>
                </Stack>
            </Stack>


            <a id="learning"></a>
            <Stack>
                <Text fontWeight="700" fontSize="18px" color="#191918" >Learning & Growth</Text>
                <Stack>
                    <Text fontWeight="700" fontSize="18px" color="#949494" >Partner Name</Text>
                    <Text fontWeight="400" fontSize="14px" color="#949494" >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Magna sit lectus fermentum nulla tortor tempus curabitur.</Text>
                </Stack>
                <Stack>
                    <Text fontWeight="700" fontSize="18px" color="#949494" >Partner Name</Text>
                    <Text fontWeight="400" fontSize="14px" color="#949494" >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Magna sit lectus fermentum nulla tortor tempus curabitur.</Text>
                </Stack>
                <Stack>
                    <Text fontWeight="700" fontSize="18px" color="#949494" >Partner Name</Text>
                    <Text fontWeight="400" fontSize="14px" color="#949494" >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Magna sit lectus fermentum nulla tortor tempus curabitur.</Text>
                </Stack>
            </Stack>
            </Stack>
        </Stack>
    );
}

export default Partners;