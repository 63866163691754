import { Button, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';

function AboutPlaybook(props) {
    const history = useHistory();
    const Start = () => {
        history.push('/start')
    }
    return (
        <Stack bg="black" align="center" >

            <Stack bg="white" w={isMobile ? "100%" : "768px"} h="100vh" align="center" px="20px" spacing="17px"  pt="5px">
                <Text fontWeight="700" fontSize="24px" color="#009FE3">About The Playbook</Text>
                <Text fontWeight="700" color="#1D1D1B" fontSize="14px" textAlign="center" pb={'30px'}>
                    Sport showcases the expression of individualism, and the togetherness of a team. We are proud to work in this industry, and whilst sports has made some progress in recent years, we continue to fall short when it comes to certain areas of diversity, both on the pitch, behind the scenes and
                    across the industry.<br/><br/>

                    This playbook focuses on race, and it aims to give the sporting community a starting point to their diversity & inclusion journey, providing tips, suggestions and resources to make an impact, at any level, in their respective companies.<br/><br/>

                    We have created a simple tool that will direct users to solutions and best practices, based on their seniority within a company, as well as budget restrictions.<br/><br/>

                    We look forward to seeing you all progress on your journeys, making the sports industry better and more inclusive for everyone.
                </Text>
                <Button
                        fontWeight="700"
                        fontSize="16px"
                        w="100%"
                        color="#189DD9"
                        bg="white"
                        border="1px solid #189DD9"
                        minH="56px"
                        borderRadius="29px"
                        onClick={Start}
                    >
                        Next 
                    </Button>

            </Stack>
        </Stack>
    );
}

export default AboutPlaybook;