import { Image, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { AboutData } from '../Dummy';
import Ashanti from '../../assets/images/Ashanti Picture.png'
import Giulia from '../../assets/images/Giulia Picture.png'
import Dan from '../../assets/images/Dan Picture.png'
import Jonathan from '../../assets/images/Jon Picture.png'



const Directors = [
    {
        id: 1,
        pic: Ashanti,
        name: 'Ashanti George-Faure',
        title: ' Head of Talent'
    },
    {
        id: 2,
        pic: Giulia,
        name: 'Giulia Zecchini ',
        title: ' Commercial Strategy Lead'
    },
    {
        id: 3,
        pic: Dan,
        name: 'Dan Bedi ',
        title: 'Founder & CEO'
    },
    {
        id: 4,
        pic: Jonathan,
        name: 'Jonathan McCourt',
        title: ' Head of Football Marketing'
    },

]

const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    return (
        <Text _hover={{ cursor: 'pointer' }} onClick={toggleReadMore} className="text" textAlign="center" fontSize="14px" color="#1D1D1B" >
            {isReadMore ? text.slice(0, 70) : text}
            <span className="read-or-hide">
                {isReadMore ? "...read  more" : ""}
            </span>
        </Text>
    );
};

function AboutUs(props) {
    return (
        <Stack bg="black" align="center" >

            <Stack bg="white" w={isMobile ? "100%" : "768px"}  align="center" px="20px" spacing="17px" pb="30px" pt="5px">
                <Text fontWeight="700" fontSize="24px" color="#009FE3">About us</Text>
                <SimpleGrid columns={2}gap={'21px'} >

                    {Directors.map(director => (
                        <Stack align={'center'}spacing={0} >
                            <Image src={director.pic} />
                            <Text textAlign={'center'}fontSize={'14px'} fontWeight={'700'}>{director.name}</Text>
                            <Text textAlign={'center'} fontSize={'14px'} fontWeight={'400'}>{director.title}</Text>
                        </Stack>

                    ))}
                </SimpleGrid>
                <Text fontSize={"14px"} fontWeight={"400"} textAlign={"center"} >
                    Ashanti, Dan, Giulia and Jonathan all connected through the Sport Industry Group’s professional development leadership programme, NextGen, as they were all selected as part of the 2020 cohort. .<br /><br />

                    Independently of the ongoing programme, and after the emotional events in Minneapolis in 2020, and the murder of George Floyd, a small Diversity & Inclusion working group was set up with a view to having an immediate platform to aid discussions, learnings and education for each other. It was also an opportunity to share experiences together as a group of young people working in the same industry which still has big steps to take with regards to D&I. .<br /><br />

                    Ashanti, Dan, Giulia and Jonathan, who all have their own motivations for wanting to be involved in the group, were determined to drive some tangible actions, so the PLAYBOOK FOR CHANGE was born. With the initial support of the wider group, the team of 4 have been working closely together for 12 months to develop this tool..<br /><br />

                    “We know this is not the solution to everything. We know there are many more aspects to focus on, and include. We are not experts. But we wanted to make a small difference.” .<br /><br />

                    “This could not have been done without the support of our NextGen peers, Sports Industry and external experts, so for that, we thank you.”
                </Text>
                {/* {AboutData.map((data) =>
                    <Stack>
                        <Text fontWeight="700" fontSize="18px" color="#1D1D1B" textAlign="center">{data.title}</Text>
                        <ReadMore>{data.description}</ReadMore>
                    </Stack>
                )} */}
            </Stack>

        </Stack>
    );
}

export default AboutUs;