import { Button, Input, Radio, RadioGroup, Select, Stack, Text, Textarea, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { ethnicityExamples } from './Dummy';
import LimitedJunior from './Documents/1 - Limited Budget _ Junior Management.pdf';
import LimitedMiddle from './Documents/2 - Limited Budget _ Middle Management.pdf';
import LimitedSenior from './Documents/3 - Limited Budget _Senior Management.pdf';
import MediumJunior from './Documents/4 - Medium Budget _ Junior Management.pdf';
import MediumMiddle from './Documents/5 - Medium Budget _ Middle Management.pdf';
import MediumSenior from './Documents/6 - Medium Budget _Senior Management.pdf';
import HighJunior from './Documents/7 - High Budget _ Junior Management.pdf';
import HighMiddle from './Documents/8 - High Budget _ Middle Management.pdf';
import HighSenior from './Documents/9 - High Budget _Senior Management.pdf';
import firebase, { db } from './firebase';
import { collection, addDoc } from "firebase/firestore";

const docs = [
    LimitedJunior,
    LimitedMiddle,
    LimitedSenior,
    MediumJunior,
    MediumMiddle,
    MediumSenior,
    HighJunior,
    HighMiddle,
    HighSenior];

const initialData = {
    gender: null,
    ethnicity: null,
    industry: null,
    employees: null,
    training: null,
    interested: null,
    email: null
}

function GetStarted(props) {
    const history = useHistory();
    const [data, setData] = React.useState(initialData);
    const [document, setDocument] = useState(null);
    const toast = useToast();
    const [values, setValues] = useState({
        level: null,
        rank: null
    })

    const Finish = () => {
        // let keys = Object.keys(data);
        // let hasBlanks = false;
        // keys.map((key, index) => {
        //     if (data[key] == null) {
        //         hasBlanks = true;
        //     }
        // })

        // if (hasBlanks) {
        //     toast({
        //         title: "Warning",
        //         description: "All fields are required.",
        //         status: "warning",
        //         duration: 3000,
        //         isClosable: true,
        //     })
        //     return;
        // }
        // console.log("d",document);
        history.push(`/finish?file=${document}&email=${data.email}&title=${values}`)
    }
    const saveEmail = () => {

        try {
            const docRef = addDoc(collection(db, "d&i-emails"), { email: data.email });
            console.log("Document written with ID: ", docRef.id);
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    }

    const handleInputChange = (e) => {

        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        });
    }

    const handleRadioTrainingChange = (val) => {
        setData({
            ...data,
            training: val
        })
    }
    const handleRadioInterestChange = (val) => {
        setData({
            ...data,
            interested: val
        })
    }

    useEffect(() => { console.log("data: ", data) }, [data])
    const handleSelectOnChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        })
    }

    useEffect(() => {
        if (values.level !== null && values.rank !== null) {
            docs.map((doc, index) => {
                if (doc.toLowerCase().includes(values.level.toLowerCase()) &&
                    doc.toLowerCase().includes(values.rank.toLowerCase())) {
                    console.log("🚀 ~ docs found ~ doc", doc)
                    setDocument(doc);
                    return;
                }
            })
        }
    }, [values])


    return (
        <Stack bg="black" align="center" >
            <Stack w={isMobile ? "100%" : "768px"} px="18px" boxSizing='border-box' spacing="30px" pb="125px" bg="white" align="center">

                <form onSubmit={e => {
                    e.preventDefault();
                    Finish();
                    saveEmail();
                }}>
                    <Stack spacing={"17px"}>
                        <Stack align="center">
                            <Text fontWeight="700" fontSize="24px" color="#009FE3" onClick={Finish}>Questions</Text>
                            <Text fontWeight="700" fontSize="14px" color="#1D1D1B" textAlign="center">
                                These will help us to provide the most relevant information and results for you.
                            </Text>
                        </Stack>

                        <Stack align="center">
                            <Text fontWeight="700" fontSize="14px" color="#009FE3" textAlign="center">Please select your management level*<br />
                                ( or most applicable)</Text>
                            <Select required onChange={handleSelectOnChange} name="rank" w="259px" placeholder="Select option" bg="#E4E4E4">
                                <option value="Junior">JUNIOR LEVEL</option>
                                <option value="Middle">MIDDLE MANAGEMENT</option>
                                <option value="Senior">SENIOR MANAGEMENT</option>
                            </Select>
                        </Stack>

                        <Stack align="center" px="15px">
                            <Text fontWeight="700" fontSize="14px" color="#009FE3" textAlign="center">Please select your budget level  for D&I activity
                                ( or most applicable), either individual
                                or for an orgainsation.
                                Recommendations will be generated
                                for this budget level</Text>
                            <Select required onChange={handleSelectOnChange} name="level" placeholder="Select option" bg="#E4E4E4">
                                <option value="Limited">NO/LOW LEVEL BUDGET (£0 - £1,500)</option>
                                <option value="Medium">MEDIUM LEVEL BUDGET (£1,500 - £2,500)</option>
                                <option value="High">HIGH LEVEL BUDGET (£2,500+)</option>
                            </Select>
                        </Stack>


                        <Stack align="center" spacing="20px" pb="42px">
                            <Stack>
                                <Text fontWeight="700" fontSize="24px" color="#009FE3" textAlign="center">Additional Information</Text>
                                <Text fontWeight="700" fontSize="14px" color="#1D1D1B" textAlign="center">These will help us to understand the <br />landscape of specific industries.
                                </Text>
                            </Stack>
                            <Select onChange={handleInputChange} value={data.gender} name="gender"
                                placeholder="How would you describe your gender?" _placeholder={{ color: "#191918" }} fontSize="14px" fontWeight="600" borderRadius="none" border='none' borderBottom="1px solid #C4C4C4" color="#1D1D1B">
                                <option>Male (including transgender men)</option>
                                <option>Female (including transgender women)</option>
                                <option value="other">Prefer to self-describe as</option>
                                <option>Prefer not to say</option>
                            </Select>

                            {data.gender && data.gender.toLowerCase().includes("other") &&
                                <Stack pl={6} w="100%">
                                    <Textarea placeholder="Self-describe as:" _placeholder={{ color: "#1D1D1B" }} w="100%" m={0} name="gender_other" value={data.gender_other}
                                        borderRadius="none" border="1px solid #9A9A9A" borderColor="1px solid #9A9A9A"
                                        onChange={handleInputChange} fontSize="14px" fontWeight="600" color="#1d1d1d" />
                                </Stack>
                            }

                            <Select onChange={handleInputChange} value={data.ethnicity} name="ethnicity" placeholder="What ethnic group do you identify with?" _placeholder={{ color: "#191918" }} fontSize="14px" fontWeight="600" borderRadius="none" border='none' borderBottom="1px solid #C4C4C4" color="#1D1D1B">
                                {ethnicityExamples.map((eth, index) => (
                                    <option key={index} value={JSON.stringify(eth)}>{eth.text}</option>
                                ))}
                            </Select>

                            {data.ethnicity && JSON.parse(data?.ethnicity).text.toLowerCase().includes("other") &&
                                <Stack pl={6} w="100%">
                                    <Textarea placeholder="Describe as:" _placeholder={{ color: "#1D1D1B" }} w="100%" m={0} name="ethnicity_other" value={data.ethnicity_other}
                                        borderRadius="none" border="1px solid #9A9A9A" borderColor="1px solid #9A9A9A"
                                        onChange={handleInputChange} fontSize="14px" fontWeight="600" color="#1d1d1d" />
                                </Stack>
                            }
                            {data.ethnicity && JSON.parse(data?.ethnicity).sub_categories.length > 0 &&
                                <Stack Stack pl={6}>
                                    <Select onChange={handleInputChange} value={data.ethnicity_sub} name="ethnicity_sub" fontSize="14px" fontWeight="600" borderRadius="none" border="1px solid #9A9A9A" borderColor="#9A9A9A" outline="none">
                                        {JSON.parse(data?.ethnicity).sub_categories.map((sC, idx) => (
                                            <option key={idx} value={sC.text}>{sC.text}</option>
                                        ))}
                                    </Select>
                                </Stack>
                            }

                            {data.ethnicity_sub && data?.ethnicity_sub.toLowerCase().includes("any") &&
                                <Stack pl={6} w="100%">
                                    <Textarea
                                        placeholder="Describe as:"
                                        _placeholder={{ color: "#1D1D1B" }} w="100%" m={0} name="ethnicity_sub_other"
                                        value={data.ethnicity_sub_other}
                                        borderRadius="none"
                                        border="1px solid #9A9A9A"
                                        borderColor="1px solid #9A9A9A"
                                        onChange={handleInputChange}
                                        fontSize="14px"
                                        fontWeight="600"
                                        color="#1d1d1d" />
                                </Stack>
                            }

                            <Input
                                onChange={handleInputChange}
                                value={data.industry} name="industry"
                                maxH="44px"
                                placeholder="Describe the Industry You Work In"
                                _placeholder={{ color: "#1D1D1B" }}
                                fontSize="14px"
                                fontWeight="600"
                                borderRadius="none"
                                border='none'
                                borderBottom="1px solid #C4C4C4"
                                color="#1D1D1B"
                                outline="none" />

                            <Stack w="100%" align="center" >
                                <Text fontSize="14px" fontWeight="600" color="#189DD9" textAlign="left" >No. of employees in your company</Text>
                                <Select onChange={handleInputChange} name="employees" value={data.employees} placeholder="Select option" alignSelf="center" w="263px" bg="#E4E4E4" >
                                    <option>0-50</option>
                                    <option>51-200</option>
                                    <option>201-500</option>
                                    <option>500+</option>
                                </Select>
                            </Stack>
                            <Stack align="center" w="100%">
                                <Text fontSize="14px" fontWeight="600" color="#189DD9" >Have you undertaken any formal D&I training?</Text>
                                <RadioGroup onChange={handleRadioTrainingChange} name="training" value={data.training}>
                                    <Stack direction="row" w="100%" align="center" justify="center" fontWeight="600" fontSize="16px">
                                        <Radio value="1">Yes</Radio>
                                        <Radio value="2">No</Radio>
                                    </Stack>
                                </RadioGroup>
                            </Stack>
                            <Stack align="center" w="100%" pb="20px">
                                <Text fontSize="14px" fontWeight="600" color="#189DD9">Are you interested in learning more about D&I?</Text>
                                <RadioGroup w="100%" onChange={handleRadioInterestChange} name="interested" value={data.interested}>
                                    <Stack direction="row" w="100%" align="center" justify="center" fontWeight="600" fontSize="16px" >
                                        <Radio value="1">Yes</Radio>
                                        <Radio value="2">No</Radio>
                                    </Stack>
                                </RadioGroup>
                            </Stack>

                            <Input
                                required
                                type="email"
                                name="email"
                                onChange={handleInputChange}
                                placeholder="Your email address"
                                value={data.email}
                                _placeholder={{ color: "#1D1D1B" }}
                                fontSize="15px"
                                h="44px"
                                fontWeight="600"
                                borderRadius="none"
                                outline="none"
                                borderBottom="1px solid #C4C4C4"
                                border='none'
                                borderColor="#1D1D1B" 
                                color='#1D1D1B'
                                />



                            <Button
                                fontWeight="700"
                                fontSize="16px"
                                w="100%"
                                color="#189DD9"
                                bg="white"
                                _hover={{ color: "white", bg: "#189DD9" }}
                                _focus={{ color: "white", bg: "#189DD9" }}
                                minH="56px"
                                border="1px solid #189DD9"
                                borderRadius="29px" type="submit">
                                See Results
                            </Button>
                        </Stack>
                    </Stack>
                </form>
            </Stack>
        </Stack>
    );
}

export default GetStarted;