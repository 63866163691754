import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDuc85z7vl_CI4--WIOR7d4IAEVXXZwBig",
  authDomain: "aime-recruitment-auth.firebaseapp.com",
  projectId: "aime-recruitment-auth",
  storageBucket: "aime-recruitment-auth.appspot.com",
  messagingSenderId: "751486740340",
  appId: "1:751486740340:web:a69d8ca027ddba7f44ae21",
  measurementId: "G-TGPJ5P1ES7"
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const db = getFirestore(firebase);
export { db };