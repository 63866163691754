export const AboutData = [
    {
        "title": "Ashanti George-Faure ",
        "description": `Ashanti is Head of Talent at Refresh Sports Consultancy and Productions. He started his career working in athlete management. Whilst his current role sees him working with world-class footballers, such as Yaya Toure and leading influencers including Liv Cooke and the MIP Alumni. He is also one of the driving forces behind the Football Black List, which is an annual celebration that recognises the role that people from an African or Caribbean background play in the world of football. In addition to this, he is a consultant for BCOMS where his work sees him help to make the sports media a more diverse place
        `
    },
    {
        "title": "Dan Bedi ",
        "description": `
        Dan is an award-winning entrepreneur and currently Co-Founder & CEO of JAFA. His organisation helps sports fans cut through the noise and powering the sports conversations of tomorrow. Public speaking, playing football and helping other entrepreneurs, Dan also commits his time to aiding several charitable causes he supports as well as helping the Sports Start-up Ecosystem through LSTN (London Sports Tech Network) which he co-founded. He has been a strong advocate for diversifying all different parts of the sports industry from e-sports, sport in tech, sports media and the commercial side of the industry.`
    },
    {
        "title": "Giulia Zecchini  ",
        "description": `
        Giulia is the Commercial Strategy Lead at gaming and esports platform, FACEIT. She oversees commercial partnerships, helping brands engage with the growing gaming community in this burgeoning industry.
        She previously managed the Commercial Business Intelligence team at Formula 1 which helps to engage brands and fans alike. Prior to her role at F1, Giulia was at Nielsen Sports, managing football clubs with the likes of Manchester City, Arsenal and Chelsea as well as the Premier League, delivering sponsorship strategy insights and asset valuations across TV, Digital and Social.
        Giulia has an evident passion for both Behavioural Science and Equality, Diversity and Inclusion. She has utilised her specialised skill set to help to drive ED&I agendas across the sports industry with a particular interest in basketball both in the UK and abroad.
        `
    },
    {
        "title": "Jonathan McCourt",
        "description": ` Jonathan has led a varied career, developing his skills and knowledge across a wealth of sectors. After University, Jonathan was accepted onto a graduate programme with Federal Mogul, taking him to Belgium for 3 years. In his time with Johnstone’s Paint, Jonathan delivered the final edition of the Johstone’s Paint Trophy tournament at Wembley Stadium. More recently, Jonathan’s been a vital part of the UMBRO team, managing the marketing activity for multiple UK Football Clubs.
        He has worked tirelessly to seek great equality across the sports industry with a focus upon fostering the next generation of people working in the world of sports.`
    },

]

export const ethnicityExamples = [
    {
        id: 1,
        text: "White",
        sub_categories: [
            {
                text: "English/Welsh/Scottish/Northern Irish/British"
            },
            {
                text: "Irish"
            },
            {
                text: "Traveller"
            },
            {
                text: "Any other white background (please describe below)"
            },
        ]
    },
    {
        id: 2,
        text: "Mixed/multiple Ethnic Groups",
        sub_categories: [
            {
                text: "White and Black Caribbean"
            },
            {
                text: "White and Black African"
            },
            {
                text: "White and Asian"
            },
            {
                text: "Any of mixed/multiple background (please describe below)"
            },
        ]
    },
    {
        id: 3,
        text: "Asian/Asian British",
        sub_categories: [
            {
                text: "Indian"
            },
            {
                text: "Pakistani"
            },
            {
                text: "Bangladeshi"
            },
            {
                text: "Chinese"
            },
            {
                text: "Any other Asian background (please describe below)"
            },
           
        ]
    },
    {
        id: 4,
        text: "Black/African/Caribbean/Black British",
        sub_categories: [
            {
                text: "African"
            },
            {
                text: "Caribbean"
            },
            {
                text: "Any other Asian background  (please describe below)"
            },
           
        ]
    },
    {
        id: 5,
        text: "Arab",
        sub_categories: [
          
        ]
    },
    {
        id: 6,
        text: "Other Ethnic Group",
        sub_categories: [
           
        ]
    },
  
 
]