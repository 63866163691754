import { HStack, Image, description, Stack, Text, Link } from '@chakra-ui/react';
import React from 'react';
import { isMobile } from 'react-device-detect';
import Arrow from '../../assets/images/arrow.svg';

function Statistics(props) {
    const StatisticsData = [
        {
            title: `32 of the 338 journalists across the FIFA World Cup, Winter Olympics and Paralympics, Wimbledon, Commonwealth Games and European Championships (Athletics) are BAME. Less than 10%.`,
            description: "(BCOMS, D Word 3 Report, 2019) ",
            link:``
        },
        {
            title: `1 black sports writers across 63 researched at the 2018 FIFA World Cup.             `,
            description: `(BCOMS, D Word 3 Report, 2019)`,
            link:``
        },
        {
            title: `Zero Black sports editors at mainstream newspapers in the UK.             `,
            description: `(BCOMS, D Word 3 Report, 2019)  `,
            link:``
        },
        {
            title: `The RFU only has two black board members out of 64 across their council.  `,
            description: `(RFU website, 2021)`,
            link:``
        },
        {
            title: `EFL and Premier League have zero black board members across their organisations as of January 2021.            `,
            description: `(EFL website, 2021)`,
            link:``
        },
        {
            title: `There are only 3 black board members across 148 positions at sporting NGB's surveyed.`,
            description: `(The Telegraph, https://www.telegraph.co.uk/sport/2020/06/10/revealed-alarming-lack-black-bame-leaders-sport/ 2020)`,
            link:`https://www.telegraph.co.uk/sport/2020/06/10/revealed-alarming-lack-black-bame-leaders-sport/ 2020)`
        },
        {
            title: `Across the decade (2010-2019) only 19% of England women's football internationals are black compared to 49% in the men's game.`,
            description: `(SheKicks, https://shekicks.net/she-kicks-investigates-why-are-there-so-few-black-england-women-footballers/ 2020)`,
            link:`https://shekicks.net/she-kicks-investigates-why-are-there-so-few-black-england-women-footballers/`
        },
        {
            title: `In the GB hockey teams (Men's and Women's) at the Tokyo Olympics there were no black players and all of their senior management and board positions were all-white`,
            description: `The Guardian, https://www.theguardian.com/sport/2020/aug/03/english-hockey-has-an-endemic-race-issue-from-top-to-bottom-clubs-claim, 2020`,
            link:`https://www.theguardian.com/sport/2020/aug/03/english-hockey-has-an-endemic-race-issue-from-top-to-bottom-clubs-claim`
        },
        {
            title: `In football refereeing, there has only ever been one black football referee in the Premier League, Uriah Rennie.`,
            description: `(Black History Month, https://www.blackhistorymonth.org.uk/article/section/opinion/why-is-there-a-lack-of-bame-referees-in-the-premier-league/, 2020)`,
            link:`https://www.blackhistorymonth.org.uk/article/section/opinion/why-is-there-a-lack-of-bame-referees-in-the-premier-league/`
        },
        {
            title: `At the 2018 FIFA World Cup only 1 football manager was black and even more surprisingly, at the most recent African Cup of Nations of the 16 countries competing there were only 3 black managers. `,
            description: `(BBC Sport, https://www.bbc.co.uk/sport/football/44561029 2018)`,
            link:`https://www.bbc.co.uk/sport/football/44561029 `
        },
        {
            title: `There are only 8 black football managers across the 92 professional clubs, with only 4 of those managers hailing from the UK.`,
            description: `(ITV, https://www.itv.com/news/anglia/2021-07-20/what-its-like-to-be-one-of-englands-only-black-managers, 2021`,
            link:`https://www.itv.com/news/anglia/2021-07-20/what-its-like-to-be-one-of-englands-only-black-managers`
        },
        {
            title: `Only 1 black football owner, Ben Robinson at Burton Albion.`,
            description: `(Sky Sports, https://www.skysports.com/football/news/16229/11398495 burton-albion-chairman-ben-robinson-named-on-football-black-list#:~:text=Burton%20Albion's%20Ben%20Robinson%2C%20the,from%20the%20Championship%20in%20May., 2020)`,
            link:` https://www.skysports.com/football/news/16229/11398495`
        },

    ]
    return (
        <Stack bg="black" align="center" >
            <Stack bg="white" w={isMobile ? "100%" : "768px"} minH="100vh" align="flex-start" px="20px" spacing="17px" pb="30px" pt="5px">
                <Text fontWeight="700" fontSize="24px" color="#009FE3" w="100%" textAlign={"center"}>Statistics</Text>
                {StatisticsData.map(data => (

                    <HStack align="start" spacing="17px" w="100%">
                        <Image src={Arrow} />
                        <Stack>
                            <Text fontWeight="700" fontSize="14px" color="#1D1D1B" textAlign={"start"} >
                                {data.title}
                            </Text>
                            {data.description.toLowerCase().includes("www.") ?
                                <Link isExternal href={data.link} maxW="calc(100vw - 80px)" fontSize={"12px"}>{data.description}</Link>
                                :
                                <Text maxW="calc(100vw - 80px)" fontSize={"12px"}>{data.description}</Text>
                            }
                        </Stack>

                    </HStack>
                ))}

            </Stack>
        </Stack>
    );
}

export default Statistics;